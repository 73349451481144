import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Card, Button, CardText } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";

const ProductCard = ({ item }) => {
  const navigate = useNavigate();
  const containerRef = useRef(null);
  const { whiteListProducts, handleSetWhiteListProducts, setSingleProduct } =
    useAuth();
  const [imageWidth, setImageWidth] = useState(null);
  const [imageHeight, setImageHeight] = useState(null);
  const [randomReview, setRandomReview] = useState(null);

  useEffect(() => {
    const generateRandomReview = () => {
      const reviewNumber = Math.floor(1000 + Math.random() * 9000);
      setRandomReview(reviewNumber.toString());
    };

    generateRandomReview();
  }, []);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setImageWidth(img.width);
      setImageHeight(img.height);
    };
    img.src = item?.images[0] ?? "";
  }, [item?.images[0]]);

  const handleRedirect = (id) => {
    navigate(`/single-product/${id}`);
  };

  const imageStyle = {
    width: "100%",
  };

  if (imageWidth && imageHeight) {
    const aspectRatio = imageWidth / imageHeight;
    let splitValue = aspectRatio.toString().split(".");
    if (splitValue && splitValue.length > 0) {
      if (splitValue.length > 1) {
        splitValue = `${splitValue[0]}.${splitValue[1].charAt(0)}`;
      } else {
        splitValue = splitValue[0];
      }
    }
    splitValue = +splitValue;

    if (splitValue > 1) {
      // Landscape image
      imageStyle.height = "auto"; // Maintain aspect ratio
    } else {
      // Portrait or square image
      if (splitValue <= 1 && splitValue >= 0.8 && window.innerWidth < 455) {
        imageStyle.height = "auto";
        imageStyle.width = "100%";
      } else {
        imageStyle.height = "100%";
        imageStyle.width = "auto";
      }
    }
  }

  return (
    <Col key={item._id} style={{ maxHeight: "350px" }}>
      <Card
        style={{ height: "100%", borderRadius: 2 }}
        onClick={() => {
          setSingleProduct(item);
          handleRedirect(item._id);
        }}
      >
        <div
          style={{ height: "calc(100% - 130px)", textAlign: "center" }}
          ref={containerRef}
        >
          <Card.Img
            variant="top"
            src={item?.images[0] ?? ""}
            style={imageStyle}
          />
          {/* <span className="rating_box">
            {item.rating}
            <i className="fa-solid fa-star" style={{ color: "white" }}></i>
          </span> */}
        </div>
        <Card.Body className="p-2 position-relative">
          <div className="d-flex justify-content-between align-items-center">
            <Card.Subtitle
              style={{
                textAlign: "left",
                color: "rgb(139, 139, 163)",
                fontWeight: "600",
                fontSize: "12px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
              className="mb-0 text-ellips"
            >
              {item.title}
            </Card.Subtitle>
          </div>
          <Card.Text
            style={{
              textAlign: "left",
              fontSize: "15px",
              fontWeight: "bold",
              lineHeight: "13px",
              marginTop: "6px",
            }}
            className="mb-0"
          >
            <span
              style={{
                fontSize: "15px",
                fontWeight: "600",
                fontStyle: "normal",
                color: "rgb(53, 53, 67)",
              }}
            >
              <span style={{fontSize:'10px'}}>₹</span>
              {item.discount}{" "}
            </span>
            <span
              style={{
                color: "#8d8d8d",
                marginLeft: "5px",
                fontSize: "11px",
                textDecoration: "line-through",
              }}
            >
              {" "}
              ₹{item.price}{" "}
            </span>{" "}
            <span
              style={{
                color: "rgb(53, 53, 67)",
                marginLeft: "5px",
                fontSize: "11px",
              }}
            >{`(${(((item?.price - item.discount) / item?.price) * 100).toFixed(
              0
            )})% OFF`}</span>
          </Card.Text>
          <Card.Text
            style={{
              textAlign: "left",
              width: "max-content",
              color: "rgb(3, 141, 99)",
              fontSize: "11px",
              backgroundColor: "rgb(211, 244, 234)",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              fontWeight: "400",
              margin: "5px 0px ",
              marginBottom: "5px",
              borderRadius: "48px",
              padding: "1px 8px",
            }}
            className="mb-0"
          >
            ₹3383 with 1 Special Offers
          </Card.Text>
          <CardText
            style={{
              color: "rgb(97, 97, 115)",
              fontWeight: "600",
              fontSize: "10px",
              marginTop: "8px",
              backgroundColor: "rgb(235 235 243)",
              padding: "4px 8px",
              width: "max-content",
              borderRadius: "48px",
              marginBottom: "6px",
            }}
          >
            Free Delivery
          </CardText>

          <CardText>
            <div className="d-flex justify-content-between ">
              <div className="d-flex text-center ">
                <span className="rating_box">
                  {item.rating}
                  <i
                    className="fa-solid fa-star"
                    style={{ color: "white" }}
                  ></i>
                </span>
                <span
                  style={{
                    color: "rgb(139, 139, 163)",
                    fontSize: "12px",
                    fontWeight: "500",
                    display: "flex",
                    alignItems: "center",
                  }}
                >{`(${randomReview})`}</span>
              </div>
              <span>
                <svg
                  width="55"
                  height="20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  iconsize="20"
                  class="sc-bcXHqe fUjwpe"
                >
                  <path
                    d="M9.901 5.496a2 2 0 0 1 2-2h41.6a2 2 0 0 1 2 2v9a2 2 0 0 1-2 2h-41.6a2 2 0 0 1-2-2v-9Z"
                    fill="#FFE7FB"
                  ></path>
                  <path
                    d="M24.712 6H19.5v1.03h2.052v5.843h1.12V7.03h2.041V6ZM24.698 8.229v4.644h1.06v-2.17c0-1.09.52-1.532 1.228-1.532a.95.95 0 0 1 .353.06V8.198a.85.85 0 0 0-.363-.068c-.55 0-1.031.314-1.267.844h-.02v-.746h-.991ZM32.226 12.873V8.229h-1.07v2.67c0 .697-.481 1.188-1.09 1.188-.56 0-.884-.383-.884-1.1V8.23h-1.06v2.975c0 1.129.628 1.816 1.63 1.816.658 0 1.188-.314 1.443-.766h.05v.619h.981ZM35.25 13.02c1.1 0 1.846-.59 1.846-1.532 0-1.855-2.543-1.03-2.543-2.052 0-.304.236-.55.698-.55.422 0 .765.246.814.59l.992-.207c-.167-.706-.893-1.188-1.836-1.188-1.03 0-1.728.57-1.728 1.434 0 1.856 2.543 1.03 2.543 2.052 0 .393-.265.658-.756.658-.481 0-.874-.255-.992-.668l-.972.197c.226.795.943 1.266 1.934 1.266ZM40.083 12.97c.343 0 .638-.058.795-.136l-.118-.855a.992.992 0 0 1-.471.099c-.501 0-.747-.226-.747-.914V9.132h1.287v-.903h-1.287V6.746l-1.07.206V8.23h-.844v.903h.844v2.21c0 1.207.658 1.629 1.61 1.629ZM45.823 11.744l-.894-.265c-.206.422-.589.657-1.09.657-.746 0-1.256-.53-1.355-1.305h3.525v-.265c-.02-1.6-1.03-2.485-2.297-2.485-1.365 0-2.308 1.07-2.308 2.485 0 1.403.992 2.454 2.425 2.454.933 0 1.61-.442 1.994-1.276ZM43.73 8.906c.6 0 1.12.373 1.169 1.198h-2.406c.118-.766.56-1.198 1.237-1.198ZM46.776 10.556c0 1.463.923 2.464 2.17 2.464.619 0 1.237-.255 1.542-.854h.03v.707h.981V6h-1.07v2.828c-.246-.432-.766-.747-1.463-.747-1.247 0-2.19.992-2.19 2.475Zm1.07 0c0-.874.501-1.542 1.316-1.542.805 0 1.296.638 1.296 1.542 0 .893-.49 1.522-1.296 1.522-.795 0-1.315-.648-1.315-1.522Z"
                    fill="#9F2089"
                  ></path>
                  <path
                    d="M16.5 3.239 9.027.059a.746.746 0 0 0-.585 0L.969 3.24a.782.782 0 0 0-.47.721v6.36c0 5.321 3.139 7.611 7.947 9.622a.746.746 0 0 0 .576 0c4.809-2.01 7.948-4.3 7.948-9.622V3.96c0-.316-.186-.6-.47-.721Z"
                    fill="#FFE7FB"
                  ></path>
                  <path
                    d="m15.748 3.894-6.75-2.871a.673.673 0 0 0-.528 0l-6.75 2.87a.706.706 0 0 0-.424.652v5.745c0 4.806 2.835 6.874 7.178 8.69.167.07.353.07.52 0 4.343-1.816 7.178-3.884 7.178-8.69V4.545a.706.706 0 0 0-.424-.651Z"
                    fill="#60014A"
                  ></path>
                  <path
                    d="M10.852 6.455c.804.006 1.482.28 2.04.817.565.54.843 1.185.837 1.946l-.023 3.58c-.003.426-.37.77-.824.77-.45-.003-.814-.35-.81-.777l.022-3.58a1.098 1.098 0 0 0-.367-.85 1.216 1.216 0 0 0-.885-.35 1.247 1.247 0 0 0-.921.372c-.23.227-.344.54-.347.856l-.02 3.528c-.003.432-.376.782-.833.78-.458-.004-.828-.357-.824-.79l.022-3.548c.004-.31-.11-.617-.334-.844a1.254 1.254 0 0 0-.918-.378 1.253 1.253 0 0 0-.892.34c-.24.23-.37.513-.37.845l-.022 3.576c-.004.43-.373.777-.827.774-.455-.003-.818-.353-.815-.783l.023-3.564c.003-.66.25-1.308.714-1.799.6-.632 1.34-.948 2.199-.942.82.006 1.521.285 2.082.853.578-.565 1.272-.835 2.093-.832Z"
                    fill="#FF9D00"
                  ></path>
                </svg>
              </span>
            </div>
          </CardText>
        </Card.Body>
        {/* <Card.Footer className="px-2 bg-white border-0 pt-0">
          <Button
            className="w-100"
            variant="dark"
            style={{
              background: "var(--them-color)",
              borderColor: "var(--them-color)",
            }}
          >
            Add To Cart
          </Button>
        </Card.Footer> */}
      </Card>
    </Col>
  );
};

export default ProductCard;
