import { BrowserRouter, Router } from "react-router-dom";
import "./App.css";
import AppRouters from "./Router";

function App() {
  return (
    <div className="App">
      
        <AppRouters />
      
    </div>
  );
}

export default App;
