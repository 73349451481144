import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import {
  Container,
  InputGroup,
  Nav,
  Navbar,
  Form,
  Card,
  Row,
  Col,
  Button,
  Offcanvas,
  FloatingLabel,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { MenuItem } from "@mui/material";
const defaultLensSettingValue = {
  fullName: "",
  mobile: "",
  pincode: "",
  address1: "",
  address2: "",
  town: "",
  city: "",
  state: "",
  saveAs: "",
};

const Checkout = () => {
  const {
    selectedProduct,
    setStep,
    address,
    setAddress,
    hideAddress,
    setHideAddress,
  } = useAuth();
  const navigate = useNavigate();
  const formikRef = useRef(null);

  const handleAddress = (values) => {
    setAddress(values);
    setHideAddress(true);
    setStep(3);
    navigate("/checkout/payment");
  };
  const [initialValues, setInitialValues] = useState({
    ...defaultLensSettingValue,
    ...address,
  });

  useEffect(() => {
    setInitialValues({ ...defaultLensSettingValue, ...address });
  }, [address]);

  useEffect(() => {
    if (hideAddress) {
      window.scrollTo(0, 0);
    }
  }, [hideAddress]);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Name is required"),
    mobile: Yup.string()
      .required("Mobile No is required")
      .matches(/^[0-9]{10}$/, "Invalid mobile number"),
    // pincode: Yup.string().required("Pincode is required"),
    pincode: Yup.number()
      .required("Pincode is required")
      .integer("Pincode must be a whole number"),
    address1: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
  });

  const state = [
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chhattisgarh",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttarakhand",
    "Uttar Pradesh",
    "West Bengal",
    "Andaman and Nicobar Islands",
    "Chandigarh",
    "Dadra and Nagar Haveli",
    "Daman and Diu",
    "Delhi",
    "Lakshadweep",
    "Puducherry",
  ];

  return (
    <Container className="p-0  pb-3 position-relative d-flex flex-column justify-content-between">
      {!hideAddress ? (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          enableReinitialize
          onSubmit={handleAddress}
          innerRef={formikRef}
        >
          {({ values, getFieldProps, errors, touched, setFieldValue }) => {
            return (
              <Form>
                <Row className="g-2 ms-0 me-0">
                  <h6
                    className="card-title px-4 text-start fw-bold mb-2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "rgb(53, 53, 67)",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        iconSize="20"
                        class="sc-dlfmHC bFsYwH"
                      >
                        <path fill="#fff" d="M0 0h20v20H0z"></path>
                        <path
                          fill="#3A66CF"
                          d="M15.257 17.828c0 1.202-2.289 2.172-5.098 2.172-2.81 0-5.098-.97-5.098-2.172.015-.16.087-.334.16-.464.506-.985 2.534-1.709 4.967-1.709s4.46.724 4.953 1.71c.072.13.116.303.116.463"
                        ></path>
                        <path
                          fill="#90B1FB"
                          d="M17.053 6.43C16.53-.043 10.116 0 10.116 0S3.67-.043 3.15 6.43C2.7 12.165 8.494 16.915 9.826 17.93a.45.45 0 0 0 .275.087.48.48 0 0 0 .275-.087c1.333-1.014 7.14-5.764 6.677-11.499m-6.937 3.288c-.536 0-1.058-.16-1.492-.45a2.6 2.6 0 0 1-1-1.201 2.7 2.7 0 0 1-.159-1.55c.102-.521.362-1 .739-1.376a2.7 2.7 0 0 1 2.925-.594c.493.203.913.55 1.217.985.29.45.449.97.449 1.492 0 .71-.275 1.39-.782 1.897a2.69 2.69 0 0 1-1.897.797"
                        ></path>
                      </svg>
                    </span>
                    &nbsp;&nbsp; CONTACT DETAILS
                  </h6>
                  <div className="bg-white px-4 py-2">
                    <Col md className="mb-1">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="House No./ Building Name/ Full Address"
                          name="address1"
                          {...getFieldProps("address1")}
                        />
                      </Form.Group>
                      <ErrorMessage
                        component="span"
                        name={"address1"}
                        className={`text-danger`}
                      />
                    </Col>
                    {/* <Col className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Road name / Area / Colony"
                          name="address2"
                          {...getFieldProps("address2")}
                        />
                      </Form.Group>
                      <ErrorMessage
                        component="span"
                        name={"address2"}
                        className={`text-danger`}
                      />
                    </Col> */}

                    <Col md className="mb-3">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="number"
                          placeholder="Pincode"
                          name="pincode"
                          {...getFieldProps("pincode")}
                        />
                      </Form.Group>
                      <ErrorMessage
                        component="span"
                        name={"pincode"}
                        className={`text-danger`}
                      />
                    </Col>

                    <div className="d-flex">
                      <Col md={6} xs={6} className="me-2">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="City"
                            name="city"
                            {...getFieldProps("city")}
                          />
                        </Form.Group>
                        <ErrorMessage
                          component="span"
                          name={"city"}
                          className={`text-danger`}
                        />
                      </Col>

                      <Form.Group>
                        <Form.Select aria-label="Default select example">
                          {state?.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </Form.Select>
                        <ErrorMessage
                          component="span"
                          name="state"
                          className="text-danger"
                        />
                      </Form.Group>
                    </div>
                    {/* <Col md className="mt-2">
                      <Form.Group className="mb-3">
                        <Form.Control
                          type="text"
                          placeholder="Nearby Famous Place/Shop/School, etc. (optional)"
                          name="address1"
                          {...getFieldProps("address1")}
                        />
                      </Form.Group>
                      <ErrorMessage
                        component="span"
                        name={"address1"}
                        className={`text-danger`}
                      />
                    </Col> */}
                  </div>

                  <h6
                    className="card-title px-4 text-start fw-bold mb-2"
                    style={{
                      fontSize: "14px",
                      fontWeight: "700",
                      color: "rgb(53, 53, 67)",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="none"
                        iconSize="20"
                        class="sc-dlfmHC bFsYwH"
                      >
                        <path fill="#fff" d="M0 0h20v20H0z"></path>
                        <path
                          fill="#3A66CF"
                          d="M15.257 17.828c0 1.202-2.289 2.172-5.098 2.172-2.81 0-5.098-.97-5.098-2.172.015-.16.087-.334.16-.464.506-.985 2.534-1.709 4.967-1.709s4.46.724 4.953 1.71c.072.13.116.303.116.463"
                        ></path>
                        <path
                          fill="#90B1FB"
                          d="M17.053 6.43C16.53-.043 10.116 0 10.116 0S3.67-.043 3.15 6.43C2.7 12.165 8.494 16.915 9.826 17.93a.45.45 0 0 0 .275.087.48.48 0 0 0 .275-.087c1.333-1.014 7.14-5.764 6.677-11.499m-6.937 3.288c-.536 0-1.058-.16-1.492-.45a2.6 2.6 0 0 1-1-1.201 2.7 2.7 0 0 1-.159-1.55c.102-.521.362-1 .739-1.376a2.7 2.7 0 0 1 2.925-.594c.493.203.913.55 1.217.985.29.45.449.97.449 1.492 0 .71-.275 1.39-.782 1.897a2.69 2.69 0 0 1-1.897.797"
                        ></path>
                      </svg>
                    </span>
                    &nbsp;&nbsp; CONTACT DETAILS
                  </h6>

                  <div className="bg-white px-4 py-3 ">
                    <div className="d-flex">
                      <Col md={6} xs={6} className="me-2">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Name"
                            name="fullName"
                            {...getFieldProps("fullName")}
                          />
                        </Form.Group>
                        <ErrorMessage
                          component="span"
                          name={"fullName"}
                          className={`text-danger`}
                        />
                      </Col>

                      <Col md className="mb-3">
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="number"
                            placeholder="Contact Number"
                            name="mobile"
                            {...getFieldProps("mobile")}
                          />
                        </Form.Group>
                        <ErrorMessage
                          component="span"
                          name={"mobile"}
                          className={`text-danger`}
                        />
                      </Col>
                    </div>
                  </div>
                  {/* <h6
                    className="card-title px-4 text-start fw-bold mb-2 mt-3"
                    style={{ fontSize: "12px" }}
                  >
                    SAVE ADDRESS AS
                  </h6>
                  <div className="bg-white px-4 py-3">
                    <div className="d-flex align-items-center">
                      <div
                        className={`addressFormUI-base-addressTypeIcon ${
                          values.saveAs === "Home"
                            ? "addressFormUI-base-selectedAddressType"
                            : ""
                        }`}
                        onClick={() => setFieldValue("saveAs", "Home")}
                      >
                        Home
                      </div>
                      <div
                        className={`addressFormUI-base-addressTypeIcon ${
                          values.saveAs === "Work"
                            ? "addressFormUI-base-selectedAddressType"
                            : ""
                        }`}
                        onClick={() => setFieldValue("saveAs", "Work")}
                      >
                        Work
                      </div>
                    </div>
                    <ErrorMessage
                      component="span"
                      name={"saveAs"}
                      className={`text-danger mb-3`}
                    />
                  </div> */}
                </Row>
              </Form>
            );
          }}
        </Formik>
      ) : (
        <div>
          <div className="addressBlocks-base-finalAddress">
            <div>
              <div className="addressDetails-base-addressTitle">
                <div className="addressDetails-base-name">{address?.name}</div>
              </div>
              <div className="addressDetails-base-address">
                <div className="addressDetails-base-addressField">
                  {address?.address}
                </div>
                <div>{address?.town}</div>
                <div>
                  Address:- {address?.address1},{address?.city}, <br />{" "}
                  {address?.pincode}
                </div>

                <div className="addressDetails-base-mobile">
                  <span>Mobile: </span>
                  <span>{address.mobile}</span>
                </div>
              </div>
            </div>
            <Button
              className="addressBlocks-base-changeOrAddBtn w-100"
              variant="outline-secondary"
              onClick={() => {
                setHideAddress(false);
              }}
            >
              CHANGE OR ADD ADDRESS
            </Button>
          </div>
          <div className="mt-4 px-4">
            <h6 className="card-title text-start fw-bold">
              DELIVERY ESTIMATES
            </h6>
            {selectedProduct?.map((item) => (
              <div className="serviceability-base-list">
                <div className="serviceability-base-deliveryContainer d-flex align-items-center">
                  <img
                    src={item?.images?.[0]}
                    className="serviceability-base-imgStyle"
                  />
                  <div className="serviceability-base-deliveryInfo">
                    <div>
                      <div>
                        <span>Delivery between </span>
                        <span className="serviceability-base-estimatedDate">
                          {`${new Date(
                            Date.now() + 5 * 24 * 60 * 60 * 1000
                          ).getDate()} ${new Date(
                            Date.now() + 5 * 24 * 60 * 60 * 1000
                          ).toLocaleString("default", {
                            month: "short",
                          })}`}{" "}
                          -{" "}
                          {`${new Date(
                            Date.now() + 8 * 24 * 60 * 60 * 1000
                          ).getDate()} ${new Date(
                            Date.now() + 8 * 24 * 60 * 60 * 1000
                          ).toLocaleString("default", { month: "short" })}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className="position-sticky bottom-0  bg-white px-4 z-3">
        <Button
          className="d-flex justify-content-center align-items-center"
          variant="dark"
          style={{
            width: "100%",
            padding: "10px",
            background: "var(--them-color)",
            borderColor: "var(--them-color)",
          }}
          onClick={() => {
            if (hideAddress) {
              setStep(3);
              navigate("/checkout/payment");
            } else {
              formikRef?.current?.submitForm();
            }
          }}
        >
          {hideAddress ? "CONTINUE" : "ADD ADDRESS"}
        </Button>
      </div>
    </Container>
  );
};

export default Checkout;
