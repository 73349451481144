import React, { useState, useEffect } from "react";
import "../CountDown/CountDown.css";

const CountdownTimer = ({ totalSeconds }) => {
  const [timeLeft, setTimeLeft] = useState(totalSeconds);

  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      }
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, [timeLeft]);

  const hours = Math.floor(timeLeft / 3600);
  const minutes = Math.floor((timeLeft % 3600) / 60);
  const seconds = timeLeft % 60;

  return (
    <div>
      <div className="text-center mt-3 d-flex justify-content-center align-items-center">
        <div className="title-text">
          Meesho Daily Deals{" "}
          <span>
            <svg
              width="19"
              height="22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              iconsize="20"
              class="sc-bcXHqe bMWbfM"
            >
              <mask
                id="deal_svg__a"
                mask-type="alpha"
                maskUnits="userSpaceOnUse"
                x="1"
                y="0"
                width="17"
                height="22"
              >
                <path
                  d="M5.198.795a.73.73 0 0 0-.699.522l-2.621 8.858a1.458 1.458 0 0 0 1.568 1.862l6.018-.708L6.835 19.8a1.089 1.089 0 0 0 1.944.929l8.174-12.206c.698-1.043-.16-2.423-1.405-2.257l-5.033.671 1.724-5.183a.73.73 0 0 0-.692-.96H5.198Z"
                  fill="#fff"
                ></path>
              </mask>
              <g mask="url(#deal_svg__a)">
                <path fill="#E11900" d="M-.208.947H18.75v21.875H-.208z"></path>
              </g>
            </svg>
          </span>{" "}
        </div>
        <span className="countDown">
          <img
            src="https://images.meesho.com/images/offer-widget-group-icon/T1W36/7tepb.png"
            width={"20px"}
            alt=""
          />
          &nbsp;
          {`${hours}h: ${minutes < 10 ? "0" : ""}${minutes}m: ${
            seconds < 10 ? "0" : ""
          }${seconds}s`}
        </span>
      </div>
    </div>
  );
};

export default CountdownTimer;
